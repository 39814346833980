<template>
  <div class="After--bg">
    <div class="baColor">
      <div class="After--Popup">
        <p class="After--title">请您对本堂课进行一个真实的评价：</p>
        <div class="After--info">
          <span>米罗小星星班</span>
          <span>上课时间：2020.8.18   20：00   </span>
          <span>授课老师：夏雨荷老师</span>
        </div>
        <div class="After--score ">
          <el-form  size="mini">
            <el-form-item label="老师能力">
              <el-rate
                v-model="value"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
              </el-rate>
            </el-form-item>
            <el-form-item label="课程内容">
              <el-rate
                v-model="value"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
              </el-rate>
            </el-form-item>
            <el-form-item label="直播质量">
              <el-rate
                v-model="value"
                :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
              </el-rate>
            </el-form-item>
          </el-form>
        </div>
        <el-input
          type="textarea"
          placeholder="请在这里填写您遇到的问题或者意见建议，你的支持是我们最大的动力~"
          v-model="textarea"
          maxlength="200"
          show-word-limit
          :autosize="{ minRows: 3, maxRows: 6 }"
        >
        </el-input>
        <div class="After--but">提交评价</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "After",
    data(){
      return{
        value:null,
        textarea:null
      }
    },
    created() {
      this.init()
    },
    methods:{
      init(){
        console.log(this.$route)
        this.getApireviewInfo()
      },
      async getApireviewInfo(){
        let _res = await this.$_api('/online/app/appletCourse/reviewInfo','post',{
          "classRoomId": 839
        })
        console.log(_res)
      },
    },
  }
</script>

<style lang="scss" scoped>
.After--bg{
  /*background: url("../assets/image/bgbgbg.png") no-repeat center center;*/
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  .baColor{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    position: relative;
    .After--Popup{
      background-color: #ffffff;
      width: 694px;
      height: 520px;
      border-radius: 10px;
      position:absolute;
      left:50%;
      top:50%;
      margin-left:-347px;
      margin-top:-260px;
      padding: 30px;
      box-sizing: border-box;
      .After--title{
        color: #FB631C;
        text-align: left;
        font-size: 16px;
      }
      .After--info{
        margin-bottom: 20px;
        text-align: left;
        span{
          margin-right: 20px;
        }
        span:nth-child(1){
          font-weight: bold;
        }
      }
      .After--score{
        text-align: left;
      }
      /*.After--but{*/
      /*  background: url("../assets/image/bt_login1111111.png") no-repeat center center;*/
      /*  width: 278px;*/
      /*  height: 56px;*/
      /*  background-size: 100% 100%;*/
      /*  font-size: 20px;*/
      /*  line-height: 56px;*/
      /*  color: #ffffff;*/
      /*  font-weight: bold;*/
      /*  margin:0 auto;*/
      /*  margin-top: 20px;*/
      /*}*/
    }
  }
  /deep/ .el-form-item__content{
    margin-top: 5px;
  }
}
</style>